<template>
    <el-pagination
            :page-size="20"
            :pager-count="11"
            layout="prev, pager, next"
            :total="1000">
    </el-pagination>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'PaginationtDefaultLayout',
        props: {
            paginationData: {
                type: Object,
                default: {
                    totalPage: 0,
                    lastPage: 0,
                    page: 0,
                    pageRow: 0,
                }
            },
            setData: {
                type: Function,
                default: () => {}
            },
        },
        data() {
            return {
                
            }
        },
        computed: {

        },
        mounted() {
            
        },
        methods: {

        }
    }
</script>